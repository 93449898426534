import axios, { AxiosRequestConfig } from 'axios'; // axios
import { Scalars } from 'toolkit/types'; // Types
import { API_URL } from 'toolkit/constants'; // API url

type Thumbnail = {
  thumbnail: {
    contentUrl: string;
    height: number;
    width: number;
  };
};

export type Article = {
  about: ReadonlyArray<{
    name: string;
    readLink: string;
  }>;
  datePublished: string;
  description: string;
  image: Thumbnail;
  name: string;
  provider: ReadonlyArray<{
    image: Thumbnail;
    name: string;
    _type: string;
  }>;
  url: string;
};

export type Topic = {
  topic: string;
  data: ReadonlyArray<Article>;
};

export type DailyDose = ReadonlyArray<Topic>;

type GetTopicArticlesInput = {
  userId: Scalars['ObjectId'];
  topic: string;
};

type GetTopicArticlesResult = {
  articles: ReadonlyArray<Article>;
  subscribed: boolean;
};

// TODO: Keep these state-setting functions in the actual function call
export const getTopicArticles = async ({
  userId,
  topic,
}: GetTopicArticlesInput): Promise<GetTopicArticlesResult> => {
  const request: AxiosRequestConfig = {
    method: 'get',
    url: `${API_URL}/topics/search?userId=${userId}&query=${topic}`,
  };

  const response = await axios(request);
  const { data: articles, isSubscribed: subscribed } = response.data;

  return { articles, subscribed };
};
