// MUI components
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

type Props = {
  step: number;
};

const StepProgress = (props: Props) => {
  const { step } = props;
  const steps = ['Subscriptions', 'Done'];

  return (
    <Stepper activeStep={step}>
      {steps.map(stepName => (
        <Step key={stepName}>
          <StepLabel>{stepName}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepProgress;
