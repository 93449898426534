import { HTMLProps } from 'react'; // React types
import { BaseProps } from 'toolkit/types'; // Types
import styles from 'styles/components/Button.module.scss'; // Component styles

type Variant = 'outlined' | 'contained';

type Props = BaseProps &
  Pick<HTMLProps<HTMLButtonElement>, 'disabled' | 'onClick' | 'onMouseOver' | 'onMouseLeave'> & {
    variant?: Variant;
  };

export default function Button(props: Props) {
  const { children, className, variant, ...otherProps } = props;
  return (
    <button
      type="button"
      className={`${styles.button} ${variant === 'outlined' ? styles.outlined : styles.contained} ${
        className || undefined
      }`}
      {...otherProps}
    >
      {children}
    </button>
  );
}
