import { useState, ChangeEvent, KeyboardEvent, RefObject } from 'react'; // React
import { BaseProps } from 'toolkit/types'; // Types
import styles from 'styles/components/Input.module.scss'; // Component styles

type Props = BaseProps & {
  placeholder?: string;
  handleSubmit: (value: string) => void;
  inputRef?: RefObject<HTMLInputElement>;
  handleOwnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  ownValue?: string;
  isShare?: boolean;
};

export default function Input(props: Props) {
  const {
    className,
    placeholder,
    handleSubmit,
    inputRef,
    handleOwnChange,
    isShare,
    ownValue,
  } = props;

  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      handleSubmit(value);
    }
  };

  return (
    <input
      className={`${styles.input} ${className}`}
      ref={inputRef}
      placeholder={placeholder || undefined}
      value={isShare ? ownValue : value}
      onChange={isShare ? handleOwnChange : handleChange}
      onKeyUp={isShare ? undefined : handleKeyUp}
    />
  );
}
