import { Link, useLocation } from 'react-router-dom'; // React router
import Topbar from 'components/Topbar'; // Topbar
import { BaseProps } from 'toolkit/types'; // Types
import styles from 'styles/components/Layout.module.scss'; // Component styles

type Props = Pick<BaseProps, 'children'>;

export default function Layout(props: Props) {
  const { children } = props;

  const { pathname } = useLocation();

  return (
    <div>
      {pathname !== '/' && <Topbar />}
      <div className={styles.layout__content}>
        <div className={styles.layout__content_sizer}>{children}</div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

// Footer
function Footer() {
  return (
    <div className={styles.layout__footer}>
      <Link to="/privacy-policy" target="_blank" rel="noreferrer noopener">
        Privacy Policy
      </Link>
      <a href="mailto:info@weeklymuse.co">Message the creators</a>
    </div>
  );
}
