import axios, { AxiosRequestConfig } from 'axios'; // axios
import { Scalars } from 'toolkit/types'; // Types
import { API_URL } from 'toolkit/constants'; // API url

type UserInfo = {
  userId: Scalars['ObjectId'];
  email: string;
  subscriptions: ReadonlyArray<string>;
};

type LoginResult = {
  userInfo?: UserInfo | null;
  err?: string;
};

export const postLogin = async (email: string): Promise<LoginResult> => {
  const request: AxiosRequestConfig = {
    method: 'post',
    url: `${API_URL}/users/login`,
    data: { email },
  };

  try {
    const response = await axios(request);
    const userInfo = response.data;
    return { userInfo };
  } catch (err) {
    if (err.response.status === 401) {
      return { userInfo: null };
    }
    return { err };
  }
};

type RegistrationResult = {
  data?: UserInfo;
  errorMessage?: string;
};

export const postRegistration = async (email: string): Promise<RegistrationResult> => {
  const request: AxiosRequestConfig = {
    method: 'post',
    url: `${API_URL}/users/register`,
    data: { email },
  };

  try {
    const response = await axios(request);
    const userInfo = response.data;
    return { data: userInfo };
  } catch (err) {
    if (err.response && err.response.data) {
      if (err.response.status === 409) {
        return { errorMessage: 'User already exists' };
      }

      return { errorMessage: err.response.data };
    }
    return { errorMessage: 'Unknown server error' };
  }
};

export const validateUserId = async (userId: Scalars['ObjectId']) => {
  const request: AxiosRequestConfig = {
    method: 'get',
    url: `${API_URL}/users/validateUserId?userId=${userId}`,
  };
  try {
    const response = await axios(request);
    const { valid } = response.data;
    return valid;
  } catch {
    return false;
  }
};
