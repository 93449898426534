import { useState } from 'react'; // React
import { useHistory } from 'react-router-dom'; // React router
import GoogleButton from 'react-google-button'; // Sign in with Google button

import Layout from 'components/Layout'; // Page layout
import Button from 'components/Button'; // Button
import LogoIcon from 'assets/logo.svg'; // Logo
import LandingImage from 'assets/landing.png'; // Landing page screenshot
import TopicsImage from 'assets/topics.png'; // Topics page screenshot
import EmailImage from 'assets/email.png'; // Email screenshot
import useFirebaseAuth from 'utils/useFirebaseAuth'; // Firebase authentication hook
import useUserStore from 'utils/useUserStore'; // User store
import { GAEventRegister } from 'toolkit/analytics'; // Google analytics
import { FeedbackMessage } from 'toolkit/landing'; // Feedback message enum
import { UserStore } from 'toolkit/user-store'; // User store types
import styles from 'styles/pages/Landing.module.scss'; // Page styles

export default function Landing() {
  const history = useHistory();
  const { signInWithGoogle, signUpWithGoogle } = useFirebaseAuth();
  const [, setUserStore] = useUserStore();
  const [feedbackMessage, setFeedbackMessage] = useState(FeedbackMessage.NONE);

  // On successful signup
  const handleSuccessfulSignup = (userInfo: UserStore) => {
    setUserStore(userInfo);

    history.push('/welcome');
  };

  // On user already exists (should just sign in)
  const handleUserExists = () => {
    setFeedbackMessage(FeedbackMessage.REGISTER_ALREADY);
  };

  // On successful login
  const handleSuccessfulLogin = (userInfo: UserStore) => {
    setUserStore(userInfo);

    history.push('/dashboard');
  };

  // On user does not exist (failed login)
  const handleUnauthorizedLogin = () => {
    setFeedbackMessage(FeedbackMessage.UNAUTHORIZED);
  };

  // On unknown error
  const handleUnknownError = () => {
    setFeedbackMessage(FeedbackMessage.UNKNOWN_ERROR);
  };

  // Handle signup
  const handleSignUp = () => {
    GAEventRegister();
    signUpWithGoogle({
      handleSuccessfulSignup,
      handleUserExists,
      handleUnknownError,
    });
  };

  // Handle signin
  const handleSignIn = () => {
    signInWithGoogle({
      handleSuccessfulLogin,
      handleUnauthorizedLogin,
      handleUnknownError,
    });
  };

  return (
    <Layout>
      <div className={styles.landing}>
        <div className={styles.logo}>
          <img src={LogoIcon} alt="muse" />
        </div>
        <div className={styles.cta}>
          <h1>Keep up with your personalized news topics, all in one place</h1>
        </div>
        <div className={styles.row}>
          <div className={styles.text}>
            <div>
              <h6>
                Get a weekly Museletter for the topics you care about. Follow your news; not
                opinions, not platforms.
              </h6>
              <div className={styles.signUpWithGoogle_wrapper}>
                <GoogleButton
                  className={styles.signUpWithGoogle}
                  label="Sign up with Google"
                  onClick={handleSignUp}
                />
              </div>
              {feedbackMessage && <h6>{feedbackMessage}</h6>}
            </div>
          </div>
          <div className={styles.image}>
            <img src={LandingImage} alt="landing" />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.image}>
            <img src={TopicsImage} alt="topics" />
          </div>
          <div className={styles.text}>
            <h3>Select the news topics that you want to read about</h3>
            <h6>
              {`Go as niche as you like! Enter your varied interests on our platform; that's what
              we're here for`}
            </h6>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.text}>
            <h3>Wait for our weekly Museletter and enjoy</h3>
            <h6>We send customized Museletters every Saturday. Read, think and curate.</h6>
          </div>
          <div className={styles.image}>
            <img src={EmailImage} alt="email" />
          </div>
        </div>
      </div>
      <div className={styles.fixed_buttons}>
        <a
          href="https://www.producthunt.com/posts/muse-b0d3445e-a0b9-466f-a8ac-0cfeedd9fcaf?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-muse-b0d3445e-a0b9-466f-a8ac-0cfeedd9fcaf"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=291730&theme=light"
            alt="Muse - Build-your-own newsletter for news | Product Hunt"
            style={{ height: 40 }}
          />
        </a>
        <Button variant="outlined" className={styles.takeMeToTheApp} onClick={handleSignIn}>
          Take me to the app
        </Button>
      </div>
    </Layout>
  );
}
