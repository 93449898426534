import { useRef } from 'react'; // React
import { useHistory } from 'react-router-dom'; // React router
import Input from 'components/Input'; // Input
import SearchIcon from 'assets/search.svg'; // Search icon
import { GAEventSubmitSearch } from 'toolkit/analytics'; // Google Analytics
import styles from 'styles/components/SearchBar.module.scss'; // Component styles

export default function SearchBar() {
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (topic: string) => {
    GAEventSubmitSearch(topic);
    history.push(`/topic/${encodeURIComponent(topic)}`);
  };

  const handleSearchClick = () => {
    if (inputRef.current && inputRef.current.value) {
      handleSubmit(inputRef.current.value);
    }
  };

  return (
    <div className={styles.searchBar}>
      <Input
        className={styles.searchBar__input}
        placeholder="Search for your news (eg. Billie Eilish, Gamestop, Socotra)"
        handleSubmit={handleSubmit}
        inputRef={inputRef}
      />
      <button type="submit" onClick={handleSearchClick}>
        <img src={SearchIcon} alt="search" />
      </button>
    </div>
  );
}
