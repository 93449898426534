export const getDateString = (dateInput: string): string => {
  const tempString = new Date(`${dateInput.split('T')[0]}`);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const returnDate = `${
    months[tempString.getMonth()]
  } ${tempString.getDate()}, ${tempString.getFullYear()}`;
  return returnDate;
};
