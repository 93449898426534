import firebase from 'firebase'; // Firebase
import { UserStore } from 'toolkit/user-store'; // User store
import { postLogin, postRegistration } from 'toolkit/authentication'; // Authentication APIs

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config);

const provider = new firebase.auth.GoogleAuthProvider();

type SignInWithGoogleInput = {
  handleSuccessfulLogin: (userInfo: UserStore) => void;
  handleUnauthorizedLogin: () => void;
  handleUnknownError: () => void;
};

const signInWithGoogle = async ({
  handleSuccessfulLogin,
  handleUnauthorizedLogin,
  handleUnknownError,
}: SignInWithGoogleInput): Promise<void> => {
  try {
    const authData = await firebase.auth().signInWithPopup(provider);
    const email = authData.user?.email;

    if (!email) {
      throw new Error('Firebase authentication error');
    }

    const { userInfo, err: error } = await postLogin(email);

    if (error) {
      throw new Error('Backend authorization error');
    } else if (!userInfo) {
      handleUnauthorizedLogin();
    } else {
      handleSuccessfulLogin(userInfo);
    }
  } catch (err) {
    handleUnknownError();
  }
};

type SignUpWithGoogleInput = {
  handleSuccessfulSignup: (userInfo: UserStore) => void;
  handleUserExists: () => void;
  handleUnknownError: () => void;
};

const signUpWithGoogle = async ({
  handleSuccessfulSignup,
  handleUserExists,
  handleUnknownError,
}: SignUpWithGoogleInput): Promise<void> => {
  try {
    const authData = await firebase.auth().signInWithPopup(provider);
    const email = authData.user?.email;

    if (!email) {
      throw new Error('Firebase authentication error');
    }

    const { data: userInfo, errorMessage: error } = await postRegistration(email);

    if (error === 'User already exists') {
      handleUserExists();
    } else if (error || !userInfo) {
      throw new Error('Backend registration error');
    } else {
      handleSuccessfulSignup(userInfo);
    }
  } catch (err) {
    handleUnknownError();
  }
};

const signOut = async (): Promise<void> => {
  await firebase.auth().signOut();
};

type FirebaseAuth = {
  signInWithGoogle: (input: SignInWithGoogleInput) => Promise<void>;
  signUpWithGoogle: (input: SignUpWithGoogleInput) => Promise<void>;
  signOut: () => Promise<void>;
};

export default function useFirebaseAuth(): FirebaseAuth {
  return {
    signInWithGoogle,
    signUpWithGoogle,
    signOut,
  };
}
