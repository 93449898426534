import { useHistory } from 'react-router-dom'; // React router
import useFirebaseAuth from 'utils/useFirebaseAuth'; // Authentication functions
import useUserStore from 'utils/useUserStore'; // User store
import styles from 'styles/components/Sidebar.module.scss'; // Component styles

// Material UI Sidebar components
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

// Material UI icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TodayIcon from '@material-ui/icons/Today';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import LightBulbIcon from '@material-ui/icons/EmojiObjects';

type Props = {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
};

export default function Sidebar(props: Props) {
  const { open, setOpen } = props;
  const history = useHistory();
  const [userStore, setUserStore] = useUserStore();
  const { signOut } = useFirebaseAuth();

  const { subscriptions } = userStore;

  const handleCloseSidebar = () => setOpen(false);

  const handleOpenTopicOverview = (topic: string) => () => {
    setOpen(false);
    history.push(`/topic/${encodeURIComponent(topic)}`);
  };

  const handleSignOut = async () => {
    await signOut();
    setUserStore({
      userId: '',
      email: '',
      subscriptions: [],
    });
    history.push('/');
  };

  return (
    <Drawer
      className={styles.sidebar}
      anchor="left"
      open={open}
      onClose={handleCloseSidebar}
      classes={{
        paper: styles.sidebar__paper,
      }}
    >
      <div className={styles.sidebar__header}>
        <h6>Subscriptions</h6>
        <IconButton onClick={handleCloseSidebar}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button onClick={() => history.push('/dashboard')}>
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText>Daily Dose</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List className={styles.topicList}>
        {subscriptions &&
          subscriptions.map(subscription => (
            <ListItem button key={subscription} onClick={handleOpenTopicOverview(subscription)}>
              <ListItemIcon>
                <LightBulbIcon />
              </ListItemIcon>
              <ListItemText primary={subscription} />
            </ListItem>
          ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleSignOut}>
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          <ListItemText>Sign Out</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
}
