import { useState, useEffect } from 'react'; // React
import Layout from 'components/Layout'; // Layout
import SearchBar from 'components/SearchBar'; // Search bar
import DailyDose from 'components/dashboard/DailyDose'; // Daily dose
import { getDailyDose as getUserDailyDose } from 'toolkit/subscriptions'; // Daily dose API
import { DailyDose as DailyDoseType } from 'toolkit/topics'; // Topic type
import useUserStore from 'utils/useUserStore'; // User store
import styles from 'styles/pages/Dashboard.module.scss'; // Page styles

// import useAuth from '../../hooks/useAuth';

const Dashboard = () => {
  //   useAuth();
  const [dailyDose, setDailyDose] = useState<DailyDoseType>([]);
  const [loading, setLoading] = useState(false);
  const [userStore, setUserStore] = useUserStore();
  const { userId } = userStore;

  // Get daily dose
  const getDailyDose = async () => {
    setLoading(true);
    try {
      const dailyDoseResponse = await getUserDailyDose(userId);
      setDailyDose(dailyDoseResponse);

      const subscriptions = dailyDoseResponse.map(topic => topic.topic);
      setUserStore({ subscriptions });
    } catch (err) {
      // Empty catch block
    }
    setLoading(false);
  };

  useEffect(() => {
    getDailyDose();
  }, []);

  return (
    <Layout>
      <div className={styles.dashboard}>
        <div className={styles.searchBar_wrapper}>
          <SearchBar />
        </div>
        <DailyDose dailyDose={dailyDose} loading={loading} />
      </div>
    </Layout>
  );
};

export default Dashboard;
