// Topic section in the Daily Dose
import ArticleCard from 'components/ArticleCard'; // Article card
import { BaseProps } from 'toolkit/types'; // Types
import { Topic as TopicType, Article } from 'toolkit/topics'; // Topic type

type Props = BaseProps & {
  topic: TopicType;
};

export default function Topic(props: Props) {
  const {
    topic: { topic, data },
  } = props;

  return (
    <div>
      <h5>{topic}</h5>
      {data.map((article: Article) => (
        <ArticleCard key={article.name} article={article} />
      ))}
    </div>
  );
}
