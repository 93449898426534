import { useState } from 'react'; // React
import Button from 'components/Button'; // Button
import LogoIcon from 'assets/logo.svg'; // Logo icon
import { UnsubscribeFeedbackMessage, unsubscribe } from 'toolkit/unsubscribe'; // Types and enums
import useUserStore from 'utils/useUserStore'; // User store
import styles from 'styles/pages/Unsubscribe.module.scss'; // Page styles

export default function Unsubscribe() {
  const [feedbackMessage, setFeedbackMessage] = useState(UnsubscribeFeedbackMessage.NONE);
  const [userStore] = useUserStore();
  const { userId } = userStore;

  const handleUnsubscribe = async () => {
    const { success } = await unsubscribe(userId);
    setFeedbackMessage(
      success
        ? UnsubscribeFeedbackMessage.UNSUBSCRIBE_SUCCESS
        : UnsubscribeFeedbackMessage.UNSUBSCRIBE_FAIURE,
    );
  };

  return (
    <div className={styles.unsubscribe}>
      <div className={styles.title}>
        <img src={LogoIcon} alt="muse" />
        <h1>Muse</h1>
      </div>
      <div className={styles.message}>
        <h3>Unsubscribe :(</h3>
        <h6>
          {`We're sorry to see you go! We'll remember your favorite topics if you wish to
            come back.`}
        </h6>
      </div>
      <div className={styles.buttons}>
        <Button variant="contained" onClick={handleUnsubscribe}>
          Unsubscribe
        </Button>
        <Button variant="outlined" className={styles.backToTheApp}>
          Back to the app
        </Button>
      </div>
      <div>{feedbackMessage && <h6>{feedbackMessage}</h6>}</div>
    </div>
  );
}
