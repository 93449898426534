import ReactGA from 'react-ga'; // React Google Analytics
import { History } from 'history'; // History object type

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || '';

export const initializeGA = (): void => {
  ReactGA.initialize(GA_TRACKING_ID, { debug: false }); // Turn on Debug for more GA logging
};

export const GAEventRegister = (): void => {
  ReactGA.event({
    category: 'Registration',
    action: 'User pressed the register button on the landing page',
    label: 'Register',
  });
};

export const GAEventSubmitSearch = (query: string): void => {
  ReactGA.event({
    category: 'Search',
    action: 'User submitted a search for a topic',
    label: query,
  });
};

export const GAEventSubscribeTopic = (topic: string): void => {
  ReactGA.event({
    category: 'Subscriptions',
    action: 'User subscribed to a topic',
    label: `Subscribe ${topic}`,
    value: 1,
  });
};

export const GAEventUnsubscribeTopic = (topic: string): void => {
  ReactGA.event({
    category: 'Subscriptions',
    action: 'User unsubscribed to a topic',
    label: `Subscribe ${topic}`,
    value: -1,
  });
};

export const GAEventShareEmail = (email: string): void => {
  ReactGA.event({
    category: 'Registration',
    action: 'User shared Muse with another user',
    label: `Share with ${email}`,
  });
};

// Using router history for GA tracking: https://github.com/react-ga/react-ga/wiki/Using-history-for-GA-Tracking
let initialPageLoad = false;

export const GAlogPageView = (history: History): void => {
  if (!initialPageLoad) {
    initialPageLoad = true;
    const page = window.location.pathname;
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }

  history.listen(() => {
    const page = window.location.pathname;
    ReactGA.set({ page });
    ReactGA.pageview(page);
  });
};
