import { Scalars } from 'toolkit/types'; // Types

export type UserStore = {
  userId: Scalars['ObjectId'];
  email: string;
  subscriptions: ReadonlyArray<string>;
};

// enum of keys used in session storage
export enum Session {
  USERID = 'userId',
  EMAIL = 'email',
  SUBSCRIPTIONS = 'subscriptions',
}

export type UserStoreContextType = {
  userStore: UserStore;
  setUserStore: (userStore: Partial<UserStore>) => void;
};

export type UserStoreContextData = {
  userStore: UserStore;
  setUserStore: (userInfo: Partial<UserStore>) => void;
};
