import axios, { AxiosRequestConfig } from 'axios'; // axios
import { Scalars } from 'toolkit/types'; // Types
import { API_URL } from 'toolkit/constants'; // API url

export enum UnsubscribeFeedbackMessage {
  NONE = '',
  UNSUBSCRIBE_SUCCESS = "You've been unsubscribed.",
  UNSUBSCRIBE_FAIURE = 'Oops! An error occurred. Please refresh and try again.',
}

type UnsubscribeResult = {
  readonly success: boolean;
};

export const unsubscribe = async (userId: Scalars['ObjectId']): Promise<UnsubscribeResult> => {
  const request: AxiosRequestConfig = {
    method: 'put',
    url: `${API_URL}/users/unsubscribe`,
    data: { userId },
  };

  try {
    const response = await axios(request);
    return { success: !!response.data };
  } catch (err) {
    return { success: false };
  }
};
