import { useState, useEffect } from 'react';

import Layout from 'components/Layout'; // Layout
import ArticleCard from 'components/ArticleCard'; // Article card
import SearchBar from 'components/SearchBar'; // Search bar
import Loading from 'components/Loading'; // Loading animation
import SubscribeButton from 'components/topic-overview/SubscribeButton'; // Subscribe button
import { BaseProps } from 'toolkit/types'; // Base props
import { Article, getTopicArticles } from 'toolkit/topics'; // Topics toolkit
import { updateSubscription as updateUserSubscription } from 'toolkit/subscriptions'; // Subscriptions toolkit
import { GAEventSubscribeTopic, GAEventUnsubscribeTopic } from 'toolkit/analytics'; // Google Analytics
import useUserStore from 'utils/useUserStore'; // User store
// import useAuth from '../../hooks/useAuth';
import styles from 'styles/pages/TopicOverview.module.scss'; // Page styles

type Props = BaseProps & {
  readonly match: {
    params: {
      topic: string;
    };
  };
};

const TopicOverview = (props: Props) => {
  //   useAuth();
  const {
    match: {
      params: { topic },
    },
  } = props;
  const [userStore, setUserStore] = useUserStore();
  const { userId } = userStore;

  const [subscribed, setSubscribed] = useState(false);
  const [articles, setArticles] = useState<ReadonlyArray<Article>>([]);
  const [loading, setLoading] = useState(false);

  // Update user's subscription
  const updateSubscription = async () => {
    setLoading(true);
    try {
      const {
        subscribed: updatedSubscribed,
        subscriptions: updatedSubscriptions,
      } = await updateUserSubscription({
        userId,
        topic,
        subscribed,
      });

      setSubscribed(updatedSubscribed);
      setUserStore({ subscriptions: updatedSubscriptions });
    } catch {
      // Empty catch block
    }
    setLoading(false);
  };

  // Get articles for the current topic
  const getArticles = async () => {
    setLoading(true);
    try {
      const { articles: articlesResponse, subscribed: subscribedResponse } = await getTopicArticles(
        {
          userId,
          topic,
        },
      );
      setArticles(articlesResponse);
      setSubscribed(subscribedResponse);
    } catch {
      // Empty catch block
    }
    setLoading(false);
  };

  useEffect(() => {
    getArticles();
  }, [topic]);

  const handleSubscribeButtonClick = () => {
    if (subscribed) {
      GAEventUnsubscribeTopic(topic);
    } else {
      GAEventSubscribeTopic(topic);
    }

    updateSubscription();
  };

  return (
    <Layout>
      <div className={styles.searchBar_wrapper}>
        <SearchBar />
      </div>
      <div className={styles.topicOverview}>
        <div className={styles.header}>
          <h1>{topic}</h1>
          <SubscribeButton subscribed={subscribed} handleClick={handleSubscribeButtonClick} />
        </div>
        <div className={styles.articles}>
          {loading ? (
            <Loading />
          ) : (
            articles.map((article: Article) => <ArticleCard key={article.name} article={article} />)
          )}
        </div>
      </div>
    </Layout>
  );
};

export default TopicOverview;
