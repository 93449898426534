import { StrictMode } from 'react'; // React
import ReactDOM from 'react-dom'; // React DOM
import HandleNavigation from 'routes'; // Navigation
import { UserStoreContext, getUserStoreContextData } from 'utils/useUserStore'; // User store provider
import 'styles/globals.module.scss'; // Global styles

function App() {
  return (
    <div className="App">
      <UserStoreContext.Provider value={getUserStoreContextData()}>
        <HandleNavigation />
      </UserStoreContext.Provider>
    </div>
  );
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
