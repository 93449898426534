import { useState, useContext, createContext } from 'react'; // React
import { UserStore, Session, UserStoreContextType, UserStoreContextData } from 'toolkit/user-store'; // Types

const session = window.sessionStorage;

// returns empty string if item doesn't exist in session
function safeSessionGetItem(key: string): string {
  const result = session.getItem(key);
  if (result !== null) {
    return result;
  }
  return '';
}

// session.setItem but also supports arrays
function sessionSetItem(key: string, value: string | ReadonlyArray<string>): void {
  if (typeof value === 'string') {
    session.setItem(key, value);
  } else {
    const arrayString = JSON.stringify(value);
    session.setItem(key, arrayString);
  }
}

function safeSessionGetArray(key: string): ReadonlyArray<string> {
  const arrayString = safeSessionGetItem(key);
  if (arrayString.length === 0) {
    // value has not been set
    return [];
  }
  return JSON.parse(arrayString);
}

const updateUserStoreSessionStorage = ({ userId, email, subscriptions }: Partial<UserStore>) => {
  if (userId !== undefined) {
    sessionSetItem(Session.USERID, userId);
  }
  if (email !== undefined) {
    sessionSetItem(Session.EMAIL, email);
  }
  if (subscriptions !== undefined) {
    sessionSetItem(Session.SUBSCRIPTIONS, subscriptions);
  }
};

export const UserStoreContext = createContext<UserStoreContextType>({
  userStore: { userId: '', email: '', subscriptions: [] },
  setUserStore: () => {},
});

export function getUserStoreContextData(): UserStoreContextData {
  const [userStore, setUserStore] = useState<UserStore>({
    userId: safeSessionGetItem(Session.USERID),
    email: safeSessionGetItem(Session.EMAIL),
    subscriptions: safeSessionGetArray(Session.SUBSCRIPTIONS),
  });

  const exportedSetUserStore = (userInfo: Partial<UserStore>): void => {
    updateUserStoreSessionStorage(userInfo);
    const newUserStore = {} as UserStore;
    Object.assign(newUserStore, userStore, userInfo);
    setUserStore(newUserStore);
  };

  return { userStore, setUserStore: exportedSetUserStore };
}

export default function useUserStore(): [UserStore, (userStore: Partial<UserStore>) => void] {
  const { userStore, setUserStore } = useContext(UserStoreContext);

  return [userStore, setUserStore];
}
