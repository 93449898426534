import NewsIcon from 'assets/news.svg'; // News icon
import { Article } from 'toolkit/topics'; // Article type
import { getDateString } from 'toolkit/date'; // Date formatting
import styles from 'styles/components/ArticleCard.module.scss'; // Component styles

type Props = {
  article: Article;
};

const ArticleCard = (props: Props) => {
  const { article } = props;

  const { name, datePublished, description, url, image, provider } = article;
  const newsProvider = provider[0].name;
  const datePublishedString = getDateString(datePublished);

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div className={styles.articleCard}>
      <button type="button" onClick={handleClick}>
        <div>
          <div className={styles.imageWrapper}>
            {image ? (
              <img className={styles.thumbnail} src={image.thumbnail.contentUrl} alt={name} />
            ) : (
              <img className={styles.placeholderIcon} src={NewsIcon} alt="news" />
            )}
          </div>
          <div className={styles.content}>
            <h5>{name}</h5>
            <h6>{`${datePublishedString} | ${newsProvider}`}</h6>
            <p>{description}</p>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ArticleCard;
