export type SelectOption = {
  readonly label: string;
  readonly value: string;
};

export enum Steps {
  SUBSCRIPTIONS = 0,
  DONE = 1,
}

export const STEPS_INFO = [
  {
    title: 'Welcome',
    image: '',
    description:
      'Thanks for signing up for Muse! Muse is focused on delivering specific and niche news for all. Start by selecting 3 specific news topics of your choice.',
    subtext: 'Some examples of topics that users have chosen include:',
  },
  {
    title: 'Done!',
    image: '',
    description:
      'That’s it! Our product will send you a Museletter every Saturday morning to your inbox chockful of articles that you will be interested in. No more algorithmically-driven and sensationalized news. No more bland and boring articles. Say hello to Muse.',
    subtext:
      'To make sure that our email gets to your inbox, please check your email and respond to our message 😄. Continue on to our dashboard and add more subscriptions to your newsletter!',
  },
];

export const TOPICS = ['Ethereum', 'Semiconductors', 'Justin Trudeau', 'Adele', 'Western Sahara'];
