import { useState } from 'react'; // React
import { Link, useLocation } from 'react-router-dom'; // React router
import Sidebar from 'components/Sidebar'; // Sidebar
import MenuIcon from 'assets/menu.svg'; // Menu icon
import LogoIcon from 'assets/logo.svg'; // Logo icon
import styles from 'styles/components/Topbar.module.scss'; // Component styles

export default function MenuBar() {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const renderMenu =
    pathname !== '/privacy-policy' && pathname !== '/onboarding' && pathname !== '/share';

  const handleOpenSidebar = () => setSidebarOpen(true);

  return (
    <>
      <div className={styles.topbar}>
        {renderMenu && (
          <div className={styles.menuButton__wrapper} onClick={handleOpenSidebar}>
            <img src={MenuIcon} alt="menu" />
          </div>
        )}
        <Link to="/dashboard">
          <img className={styles.logo} src={LogoIcon} alt="muse" />
        </Link>
      </div>
      {renderMenu && <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />}
    </>
  );
}
