import Loading from 'components/Loading'; // Loading animation
import Topic from 'components/dashboard/Topic'; // Topic
import { BaseProps } from 'toolkit/types'; // Base props
import { Topic as TopicType, DailyDose as DailyDoseType } from 'toolkit/topics'; // Daily dose type
import styles from 'styles/components/dashboard/DailyDose.module.scss'; // Component styles

type Props = BaseProps & {
  dailyDose: DailyDoseType;
  loading: boolean;
};

const DailyDose = (props: Props) => {
  const { dailyDose, loading } = props;

  return (
    <div className={styles.dailyDose}>
      <h1>Your Daily Dose</h1>
      {loading ? (
        <Loading />
      ) : dailyDose.length > 0 ? (
        dailyDose.map((topic: TopicType) => <Topic key={topic.topic} topic={topic} />)
      ) : (
        <p>You do not have any subscriptions! Search above to add some topics.</p>
      )}
    </div>
  );
};

export default DailyDose;
