import axios, { AxiosRequestConfig } from 'axios'; // axios
import { Scalars } from 'toolkit/types'; // Types
import { DailyDose } from 'toolkit/topics'; // Daily dose type
import { API_URL } from 'toolkit/constants'; // API url

type UpdateSubscriptionInput = {
  userId: Scalars['ObjectId'];
  topic: string;
  subscribed: boolean;
};

type UpdateSubscriptionResult = {
  subscribed: boolean;
  subscriptions: ReadonlyArray<string>;
};

// TODO: Keep these state-setting functions in the actual function call
export const updateSubscription = async ({
  userId,
  topic,
  subscribed,
}: UpdateSubscriptionInput): Promise<UpdateSubscriptionResult> => {
  const request: AxiosRequestConfig = {
    method: 'put',
    url: `${API_URL}/topics/${subscribed ? 'unsubscribe' : 'subscribe'}`,
    data: {
      userId,
      topic,
    },
  };

  const response = await axios(request);
  const {
    isSubscribed: updatedSubscribed,
    user: { subscriptions: updatedSubscriptions },
  } = response.data;
  return {
    subscribed: updatedSubscribed,
    subscriptions: updatedSubscriptions,
  };
};

export const getDailyDose = async (userId: Scalars['ObjectId']): Promise<DailyDose> => {
  const request: AxiosRequestConfig = {
    method: 'get',
    url: `${API_URL}/dailyDose/${userId}`,
  };
  const response = await axios(request);
  return response.data;
};
