import axios, { AxiosRequestConfig } from 'axios'; // axios
import { API_URL } from 'toolkit/constants'; // API url

type ShareType = {
  shareEmailAddress: string;
  shareSender: string;
};

type ShareResult = {
  readonly success: boolean;
};

export enum ShareFeedbackMessage {
  NONE = '',
  SHARE_SUCCESS = 'Invite sent. Thanks for sharing our product!',
  SHARE_FAILURE = 'Oops! An error occurred. Please refresh and try again.',
}

export const shareMuse = async (shareInfo: ShareType): Promise<ShareResult> => {
  const request: AxiosRequestConfig = {
    method: 'post',
    url: `${API_URL}/users/share`,
    data: shareInfo,
  };

  try {
    const response = await axios(request);
    return { success: !!response.data };
  } catch (err) {
    return { success: false };
  }
};
