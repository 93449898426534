import Loader from 'react-loader-spinner'; // Loading animation
import styles from 'styles/components/Loading.module.scss'; // Component styles

export default function Loading() {
  return (
    <div className={styles.loadingBarWrapper}>
      <Loader type="Bars" height={64} width={64} />
    </div>
  );
}
