import { useState } from 'react'; // React
import Button from 'components/Button'; // Button
import styles from 'styles/components/topic-overview/SubscribeButton.module.scss'; // Component styles

type Props = {
  subscribed: boolean;
  handleClick: () => void;
};

export default function SubscribeButton(props: Props) {
  const { subscribed, handleClick } = props;

  const [hover, setHover] = useState(false);

  return (
    <Button
      onClick={handleClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={subscribed ? styles.subscribed : undefined}
      variant={subscribed ? 'contained' : 'outlined'}
    >
      {subscribed ? (hover ? 'Unsubscribe' : 'Subscribed') : 'Subscribe'}
    </Button>
  );
}
