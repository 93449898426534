import { useEffect } from 'react'; // React
import { Route, RouteProps, useLocation, useHistory } from 'react-router-dom'; // Route
import { validateUserId } from 'toolkit/authentication'; // User id validation
import useUserStore from 'utils/useUserStore'; // User store

type Props = RouteProps;

export default function ProtectedRoute(props: Props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [userStore] = useUserStore();
  const { userId } = userStore;

  const redirectToLogin = () => {
    history.push(`/?redirect=${pathname}`);
  };

  const validate = async () => {
    if (!userId) {
      redirectToLogin();
    } else {
      const valid = await validateUserId(userId);
      if (!valid) {
        redirectToLogin();
      }
    }
  };

  useEffect(() => {
    validate();
  }, [userStore.userId]);

  return <Route {...props} />;
}
