import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // React router
import ProtectedRoute from 'components/ProtectedRoute'; // Protected route

// App pages
import Landing from 'pages/Landing';
import Dashboard from 'pages/Dashboard';
import TopicOverview from 'pages/TopicOverview';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Unsubscribe from 'pages/Unsubscribe';
import Onboarding from 'pages/Onboarding';
import Share from 'pages/Share';

export default function HandleNavigation() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Landing} />
        <ProtectedRoute path="/dashboard" exact component={Dashboard} />
        <ProtectedRoute path="/topic/:topic" exact component={TopicOverview} />
        <ProtectedRoute path="/welcome" exact component={Onboarding} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <ProtectedRoute path="/unsubscribe" exact component={Unsubscribe} />
        <Route path="/share" exact component={Share} />
      </Switch>
    </Router>
  );
}
