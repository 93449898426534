import { useState, useRef, ChangeEvent } from 'react'; // React
import Layout from 'components/Layout'; // Layout
import Button from 'components/Button'; // Button
import Input from 'components/Input'; // Input
import { ShareFeedbackMessage, shareMuse } from 'toolkit/share'; // Share feedback enum
import styles from 'styles/pages/Share.module.scss'; // Page styles

export default function Share() {
  const [shareSender, setShareSender] = useState('');
  const [shareEmailAddress, setShareEmailAddress] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState(ShareFeedbackMessage.NONE);
  const inputRef = useRef<HTMLInputElement>(null);

  // Handlers
  const handleSubmit = async () => {
    const { success } = await shareMuse({
      shareEmailAddress,
      shareSender,
    });

    if (success) {
      setFeedbackMessage(ShareFeedbackMessage.SHARE_SUCCESS);
    } else {
      setFeedbackMessage(ShareFeedbackMessage.SHARE_FAILURE);
    }
  };

  const handleShareEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setShareEmailAddress(event.target.value);
  };

  const handleShareNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setShareSender(event.target.value);
  };

  return (
    <Layout>
      <div className={styles.share}>
        <div className={styles.content}>
          <div className={styles.shareText}>
            <h3>Share Muse</h3>
            <h4>
              Thank you for sharing our product! We will send an invite email to the person you
              choose to share with.
            </h4>
          </div>
          <div className={styles.buttons}>
            <Input
              className=""
              placeholder="Enter your name"
              isShare
              ownValue={shareSender}
              handleOwnChange={handleShareNameChange}
              handleSubmit={(value: string) => setShareSender(value)}
              inputRef={inputRef}
            />
            <Input
              className=""
              placeholder="Enter the email address you want to share to"
              isShare
              ownValue={shareEmailAddress}
              handleOwnChange={handleShareEmailChange}
              handleSubmit={(value: string) => setShareEmailAddress(value)}
            />
            <Button variant="contained" onClick={handleSubmit}>
              Share
            </Button>
          </div>
          <div className={styles.shareText}>{feedbackMessage}</div>
        </div>
      </div>
    </Layout>
  );
}
