import { useState, KeyboardEvent } from 'react'; // React
import { useHistory } from 'react-router-dom'; // React router
import Select from 'react-select/creatable'; // React select
import Layout from 'components/Layout'; // Layout
import Button from 'components/Button'; // Button
import StepProgress from 'components/onboarding/StepProgress'; // Step progress
import { SelectOption, Steps, STEPS_INFO, TOPICS } from 'toolkit/onboarding'; // Onboarding types and constants
import { updateSubscription } from 'toolkit/subscriptions'; // Update subscription
import useUserStore from 'utils/useUserStore'; // User store
import styles from 'styles/pages/Onboarding.module.scss'; // Page styles

// MUI icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function Onboarding() {
  const history = useHistory();
  const [userStore, setUserStore] = useUserStore();
  const { userId } = userStore;

  const [step, setStep] = useState(Steps.SUBSCRIPTIONS);
  const [subscriptions, setSubscriptions] = useState<ReadonlyArray<SelectOption>>([]);
  const [customTopic, setCustomTopic] = useState('');
  const stepInfo = STEPS_INFO[step];
  const { title, description, subtext } = stepInfo;

  // Update user subscriptions
  const updateSubscriptions = async (topics: ReadonlyArray<SelectOption>) => {
    const topicsToSubscribe = topics.map((topic: SelectOption) => topic.value);
    // TODO: Bulk requests
    let updatedSubscriptions: string[] = [];
    for (const topic of topicsToSubscribe) {
      const { subscriptions: subscriptionsResponse } = await updateSubscription({
        userId,
        topic,
        subscribed: false,
      });
      updatedSubscriptions = [...subscriptionsResponse];
    }
    setUserStore({
      subscriptions: updatedSubscriptions,
    });
  };

  // Handle navigation to previous/next onboarding pages
  const handleGoToPreviousPage = () => setStep(step - 1);
  const handleGoToNextPage = () => {
    setStep(step + 1);
  };

  // Add an entry to the Select component
  const addCustomTopic = (label: string) => ({
    label,
    value: label,
  });

  // Handlers for selection changes
  const handleSelectChange = (newSubscriptions: any) => {
    setSubscriptions(newSubscriptions);
  };
  const handleInputChange = (topic: string) => {
    setCustomTopic(topic);
  };

  // Handle updates to subscriptions and custom topic
  const handleKeyDown = (event: KeyboardEvent) => {
    if (!customTopic) return;
    if (event.key === 'Enter') {
      event.preventDefault();
      setSubscriptions([...subscriptions, addCustomTopic(customTopic)]);
      setCustomTopic('');
    }
  };

  // Redirect to dashboard upon completion of onboarding
  const redirectToDashboard = async () => {
    await updateSubscriptions(subscriptions);
    history.push('/dashboard');
  };

  return (
    <Layout>
      <div className={styles.onboarding}>
        <div className={styles.stepProgress_wrapper}>
          <StepProgress step={step} />
        </div>
        <div className={styles.content}>
          <h3>{title}</h3>
          <h4>{description}</h4>
          <h5>{subtext}</h5>
          {step === Steps.SUBSCRIPTIONS && (
            <div className={styles.subscriptionsStep}>
              <div className={styles.topics}>
                {TOPICS.map(topic => (
                  <div key={topic}>{topic}</div>
                ))}
              </div>
              <div className={styles.customTopicSelect}>
                <Select
                  isMulti
                  isClearable
                  menuIsOpen={false}
                  components={{ DropdownIndicator: null }}
                  onChange={handleSelectChange}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  inputValue={customTopic}
                  value={subscriptions}
                  placeholder="Add your own topics!"
                />
              </div>
            </div>
          )}
          <div className={styles.buttons}>
            <Button
              variant="outlined"
              disabled={step === Steps.SUBSCRIPTIONS}
              onClick={step !== Steps.SUBSCRIPTIONS ? handleGoToPreviousPage : undefined}
            >
              <ArrowBackIcon />
              <span>Previous</span>
            </Button>
            <Button
              variant="outlined"
              onClick={step === Steps.DONE ? redirectToDashboard : handleGoToNextPage}
            >
              <span>{step === Steps.DONE ? 'To the dashboard' : 'Next'}</span>
              <ArrowForwardIcon />
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
